<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5 background justify-content-center"
      >
        <!-- <b-img
          fluid
          :src="imgUrl"
          class=""
          alt="Login V2"
        /> -->
        <h1 class="text-uppercase text-center text-light display-3">
          {{ $t('loginTitle') }}
        </h1>
      </b-col>
      <!-- /Left Text-->

      <!-- Register Form -->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-col
          sm="8"
          md="6"
          lg="12"
          class="px-xl-2 mx-auto"
        >
          <div class="d-flex justify-content-center mb-3">
            <b-img
              :src="appLogoImage"
              alt="logo"
              width="150"
            />
          </div>
          <b-card-title
            class="mb-1 font-weight-bold"
            title-tag="h2"
          >
            {{ $t('createAccount') }} 👋
          </b-card-title>
          <b-card-text class="mb-2">
            YoConstruyo
          </b-card-text>

          <!-- Form -->
          <validation-observer ref="registerValidation">
            <b-form @submit.prevent="register()">
              <b-form-group label="Nombre">
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input v-model="user.name" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <b-form-group label="Email">
                <validation-provider
                  #default="{ errors }"
                  name="Email"
                  rules="required"
                >
                  <b-form-input v-model="user.email" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- WhatsApp Field -->
              <b-form-group label="WhatsApp">
                <validation-provider
                  #default="{ errors }"
                  name="WhatsApp"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.phone"
                    autocomplete="off"
                    type="number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>

              <!-- Referred By Field -->
              <b-form-group label="Referido (Opcional)">
                <b-form-input
                  v-model="user.referred_by"
                  autocomplete="off"
                  type="text"
                />
                <small class="text-muted">Si tienes un código de referido, introdúcelo aquí para obtener beneficios especiales.</small>
              </b-form-group>
              <b-overlay
                :show="loading"
                rounded
                opacity="0.6"
                spinner-small
                spinner-variant="primary"
                class="d-inline-block"
              >
                <b-button
                  type="submit"
                  variant="primary"
                >
                  {{ $t('createAccount') }}
                </b-button>
              </b-overlay>
            </b-form>
          </validation-observer>
          <div class="mt-1">
            <span
              class="text-primary cursor-pointer"
              block
              @click="$router.push({ name: 'auth-login' })"
            >
              {{ $t('alreadyHaveAnAccount') }}
            </span>
          </div>
        </b-col>
      </b-col>
      <!-- /Register Form-->
    </b-row>
    <b-modal
      id="register-complete"
      ok-only
      :title="$t('registerComplete')"
    >
      <div>
        <h4>
          {{ $t('registerCompleteInfo') }}
        </h4>
      </div>
    </b-modal>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { mapActions } from 'vuex'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import { $themeConfig } from '@themeConfig'
import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
// import useJwt from '@/auth/jwt/useJwt'
import store from '@/store/index'
// import { getHomeRouteForLoggedInUser } from '@/auth/utils'

export default {
  setup() {
    const { appName, appLogoImage } = $themeConfig.app
    return {
      appName,
      appLogoImage,
    }
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      loading: false,
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/login/login.jpg'),

      // validation rules
      required,
      email,
      user: {
        name: null,
        email: null,
        phone: null,
        supplier: null,
        role_name: 'customer',
        scoped_roles: [],
        role_resource_id: null,
        referred_by: '',
      },
    }
  },
  mounted() {
    // Verifica si la URL contiene el parámetro 'referred_by' y lo asigna al campo 'referred_by'
    if (this.$route.query.referred_by) {
      this.user.referred_by = this.$route.query.referred_by
    }

    // Mueve la lógica para asignar la imagen dependiendo del tema aquí
    if (store.state.appConfig.layout.skin === 'dark') {
      this.sideImg = require('@/assets/images/login/login.jpg')
    } else {
      this.sideImg = require('@/assets/images/login/login.jpg') // Cambia la imagen para el tema claro si es necesario
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/login/login.jpg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  methods: {
    ...mapActions('auth', ['createCustomer']),
    register() {
      this.$refs.registerValidation.validate().then(success => {
        if (success) {
          this.loading = true
          this.createCustomer(this.user)
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Registro exitoso',
                  icon: 'EditIcon',
                  variant: 'success',
                },
              })
            })
            .then(() => {
              // this.$router.push({ name: 'auth-login' })
              this.$bvModal.show('register-complete')
              this.resetForm()
            })
            .finally(() => {
              this.loading = false
            })
        }
      })
    },
    resetForm() {
      this.user.name = ''
      this.user.email = ''
      this.user.phone = ''
      this.user.referred_by = ''
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/pages/page-auth.scss';
.background {
  background-image: url('../../assets/images/login/login.jpg');
  background-size: cover;
  background-position: center center;
}
</style>
